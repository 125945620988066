import React from 'react'
import './css/Footer.scss'

function Footer() {
  return (
    <footer>
      <span>This website uses TMDB and the TMDB APIs but is not endorsed, certified, or otherwise approved by TMDB.</span>
    </footer>
  )
}

export default Footer